/* Global */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&family=Playfair+Display:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body, h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
}

h1, h2, h3, h4, h5, h6, p, li, input, label, a, button, textarea, .address {
    font-family: 'Montserrat', sans-serif;
}

ul {
    list-style-type: none;
}

button {
    cursor: pointer;
    border: none;
    outline: none;
}

a {
    color: inherit;
    text-decoration: none;
}

ol {
    list-style-type: disc;
}

a, button {
    color: #000;
}

.d-mobile {
    display: none;
}

.d-mobile-important {
    display: none !important;
}

.thin {
    font-weight: 400 !important;
}

.uppercase {
    text-transform: uppercase;
}

.noWrap {
    white-space: nowrap;
}

/* Top menu */
.topMenu {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 5px 3px #0000000D;
    padding: 0 clamp(10%, calc((100vw - 1400px) / 2), 10%);
    background: #fff;
    transform-origin: top;
    transition: .2s all ease-in-out;
}

.topMenu__list__item__link:hover .cartCounter {
    transform: translateY(-5px);
}

.topMenu__logo {
    width: 100px;
    height: auto;
    transition: .2s all ease-in-out;
}

.topMenu__logo:hover {
    transform: scale(1.15);
}

.topMenu__menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.topMenu__list {
    display: flex;
    justify-content: center;
    align-items: center;
}

.topMenu__list__item {
    margin: 0 30px;
    transition: .2s all ease-in-out;
}

.topMenu__languages--mobile {
    margin-top: 20px;
}

.topMenu__languages {
    margin-left: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.topMenu__languages--mobile {
    margin-left: 0 !important;
}

.topMenu__languages--mobile * {
    margin: 15px !important;
}

.topMenu__list__item__link {
    font-weight: 700;
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    transition: .2s all ease-in-out;
}

.topMenu__list__item:hover {
    color: #D6B04A;
}

.topMenu__list__item__link:hover {
    color: #D6B04A;
}

.topMenu__languages__btn {
    background: transparent;
    margin: 0 10px;
    padding: 0;
    border: none;
}

.topMenu__languages__btn:last-child {
    margin-right: 0;
}

.mobileMenu {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    margin: 0;
    padding: 0;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    transition: .2s all ease-in-out;
    transform-origin: right;
}

.mobileMenu > * {
    opacity: 0;
    transition: .2s all ease-in-out;
}

.mobileMenu__closeBtn {
    background: url("../img/close.png");
    background-size: 100% 100%;
    position: absolute;
    top: 70px;
    right: 20px;
    width: 20px;
    height: 20px;
}

.mobileMenu__closeImg {
    width: 100%;
    height: auto;
}

.mobileMenu__logo {
    width: 50%;
    height: auto;
    max-width: 300px;
    margin-top: -10vh;
}

.mobileMenu__list {
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
}

.mobileMenu__list li {
    margin-bottom: 15px;
}

.topMenu__list__item__img {
    width: 23px;
}

.cartCounter {
    position: absolute;
    top: -18px;
    right: -12px;
    font-style: normal;
    background: #000;
    color: #fff;
    width: 16px;
    height: 16px;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s all ease-in;
}

/* Landing */
.landing {
    position: relative;
    width: 100%;
    height: auto;
    padding: 100px clamp(10%, calc((100vw - 1400px) / 2), 10%) 50px;
    margin-top: 70px;
}

.landing__img {
    position: absolute;
    top: 0;
    left: 0;
    width: clamp(100%, 100%, 2500px);
    height: auto;
}

.landing__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    min-width: 300px;
}

.landing__logo {
    width: 380px;
    height: auto;
}

.landing__header {
    font-family: 'Playfair display', cursive;
    font-weight: 400;
    font-size: 52px;
    margin: 60px 0;
    text-align: center;
}

.button--landing {
    width: 400px;
    height: 65px;
    background: #000;
    color: #fff;
    border-radius: 4px;
    transition: .4s all ease-in-out;
    font-style: italic;
}

.button--landing {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.button--landing::after {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(45deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0.0) 100%
    );
}

.button--landing:hover::after,
.homePageSection__item:hover .button--landing::after
{
    opacity: 1;
    top: -10%;
    left: 50%;
    transition-property: left, top, opacity;
    transition-duration: 1.4s, 1.4s, 1s;
    transition-timing-function: ease;
}

.button--landing__link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    z-index: 5;
}

/* Home page section */
.homePageSection {
    padding-bottom: 50px;
    position: relative;
    width: 100%;
    background: transparent;
    /*background: url("../img/marmur.jpg");*/
    background-size: 100%;
    padding-top: 60px;
    overflow: hidden;
}

.homePageSection .homePageSection__item__content {
    order: 2;
}

.homePageSection .homePageSection__imgWrapper {
    order: 1;
}

.homePageSection__item--odd .homePageSection__item__content {
    order: 1;
}

.homePageSection__item--odd .homePageSection__imgWrapper {
    order: 2;
}

.homePageSection__item .homePageSection__item__content {
    margin-right: 50px;
    margin-left: 0;
}

.homePageSection__item--odd .homePageSection__item__content {
    margin-right: 50px;
    margin-left: 0;
}

.homePageSection__item:not(.homePageSection__item--odd) .homePageSection__text {
    margin-left: 50px;
}

.homePageSection__item:not(.homePageSection__item--odd) .button--landing {
    margin-left: 50px;
}

#zestawy {
    position: absolute;
    top: -50px;
    left: 0;
}

.homePageSection__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}

.homePageSection__text {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
}

.homePageSection__item__content {
    width: 40%;
}

.homePageSection__text--marginLeft {
    margin-right: 0;
    margin-left: 50px;
}

.homePageSection__imgWrapper {
    width: 35%;
    height: auto;
}

.homePageSection__img {
    width: 100%;
    height: auto;
}

/* Footer */
.topBar {
    background-color: #fff;
    background-image: url("../img/footer-background.png");
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    color: #fff;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    transform-origin: top;
    transition: .4s all ease-in-out;
    padding: 0 clamp(10%, calc((100vw - 1400px) / 2), 10%);
    border-bottom: 1px solid rgba(0, 0, 0, .15);
}

.topBar .footer__header {
    font-size: 12px;
}

.topBar .callToAction {
    height: 25px;
    font-size: 9px;
}

.topBar .footer__menu__item__img {
    max-width: 18px;
}

.footer {
    background: #000;
    color: #fff;
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px clamp(10%, calc((100vw - 1400px) / 2), 10%);
    border-top: 3px solid #000;
}

.footer__paymentBanner {
    width: 40%;
    border-radius: 5px;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    margin-left: 8vw;
}

.footer__paymentBanner .img {
    width: 12%;
}

.footer__menu {
    position: absolute;
    right: clamp(10%, calc((100vw - 1400px) / 2), 10%);
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}

.footer__menu__item:last-of-type {
    margin-right: 0;
}

.footer__list--region {
    gap: 16px;
}

.footer__list__item {
    background: transparent;
    font-size: 14px;
    border: none;
}

.footer__list__item--selected {
    font-weight: 700;
    text-decoration: underline;
}

.footer__header {
    color: #000;
    font-size: 14px;
    font-weight: 700;
}

.footer__list {
    display: flex;
    align-items: center;
}

.footer__menu__item {
    margin: 0 15px;
    font-size: 14px;
    color: #fff;
    opacity: .85;
    transition: .2s all;
}

.footer__header, .footer__menu__item {
    font-style: italic;
}

.footer__menu__item:hover {
    opacity: 1;
}

.footer__headerWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.footer__header--label {
    text-transform: uppercase;
    margin-right: 15px;
    color: #D5D5D5;
    font-style: normal;
}

.footer__header__link {
    text-align: center;
    font-style: normal;
}

.italic {
    font-style: italic;
}

.topBar .footer__header {
    transition: .2s all ease-in-out;
}

.topBar .footer__header:last-of-type:hover {
    opacity: .85;
}

/* Offer page */
.offerContent {
    overflow-x: hidden;
    margin-top: 280px;
    padding: 0 clamp(10%, calc((100vw - 1400px) / 2), 10%);
}

.offerContent__logo {
    width: 200px;
}

.offerContent__header {
    text-align: center;
    margin: 20px auto 10px;
    font-family: 'Playfair display', cursive;
    font-size: 92px;
    font-weight: 500;
    max-width: 90vw;
}

.offerContent__header--2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 24px;
    margin-top: -5px;
}

.offerContent__subheader {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    margin: 15px auto 120px;
}

.offerContent__grid {
    width: 100%;
    max-width: 1100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 5vw;
    grid-row-gap: 90px;
    margin: 50px auto 200px;
}

.offerContent__item {
    overflow: hidden;
    width: 100%;
}

.offerContent__item__border {
    position: relative;
    padding-bottom: 15px;
}

.offerContent__imgWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
}

.offerContent__item__img {
    width: 100%;
    height: auto;
    min-width: 100%;
    /*min-height: 100%;*/
    max-width: 150%;
    /*max-height: 150%;*/
}

.offerContent__item__header {
    font-size: 38px;
    padding: 10px 0;
    width: 100%;
    font-weight: 700;
    border-radius: 4px;
}

.offerContent__item__header--cursive {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    padding-top: 8px;
    font-family: 'Montserrat', sans-serif;
    min-height: 40px;
}

.offerContent__item__price {
    font-size: 24px;
    margin-bottom: 8px;
}

.categories__item__header {
    background: #E4E4E4;
    width: 100%;
    height: 40px;
    text-transform: uppercase;
    border: 2px solid #000000;
    margin-top: 15px;
    font-weight: 700;
    border-radius: 4px;
    font-style: italic;
    position: relative;
    z-index: 3;
}

.offerContent__item__btn {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    transition: .2s all;
}

.offerContent__item__btn:hover {
    transform: translateY(-2px);
}

.categories__item__header {
    text-align: center;
    background: #000;
    color: #fff;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

/* Single product */
.singleProduct {
    margin: 0 auto 70px;
    padding: 220px clamp(5%, calc((100vw - 1300px) / 2), 20%) 100px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
}

.singleProduct--img {
    position: absolute;
    z-index: -1;
}

.singleProduct--img--1 {
    right: -120px;
    top: 190px;
}

.singleProduct--img--3 {
    right: -200px;
    top: 620px;
}

.singleProduct--img--2 {
    top: 700px;
    left: -20px;
}

.singleProduct--img--4 {
    top: 80px;
    left: -20px;
}

.singleProduct__left {
    width: 500px;
    margin-right: 50px;
}

.marginRight15 {
    margin-right: 15px;
}

.singleProduct__right {
    width: calc(100% - 620px);
}

.allergensImg--client {
    margin: 0 5px 15px;
    filter: grayscale(1);
}

.allergensImg--client:hover {
    filter: grayscale(0);
}

.singleProduct__images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.singleProduct__options--allergens {
    display: block !important;
}

.singleProduct__mainImage {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    background: transparent;
    align-items: center;
    justify-content: center;
}

.singleProduct__images__item {
    width: 32%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.singleProduct__img {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.singleProduct__right__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.singleProduct__title {
    font-family: 'Playfair display', cursive;
    font-size: 42px;
    font-weight: 500;
    padding-bottom: 8px;
}

.singleProduct__title--cursive {
    font-family: 'Playfair display', cursive;
    display: block;
}

.singleProduct__price {
    margin-right: 40px;
    white-space: nowrap;
    font-size: 32px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.singleProduct__options {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.singleProduct__options__header {
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
}

.singleProduct__options__buttons {
    margin-left: 15px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.allergens__inner > img:first-of-type {
    margin-left: 0;
}

.singleProduct__options__btn {
    padding: 14px 17px;
    background: #fff;
    margin: 0 9px 0 0;
    text-transform: uppercase;
    border: 3px solid #000;
    color: #000;
    font-weight: 700 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    font-style: normal;
    border-radius: 50%;
    transition: .4s all;
}

.singleProduct__options__buttons--lower .singleProduct__options__btn--version {
    width: 200px;
    height: 35px;
    border-radius: 35px;
    margin-bottom: 10px;
}

.singleProduct__options__buttons--lower .singleProduct__options__btn {
    padding: 7px 10px;
}

.singleProduct--checked {
    color: #fff;
    background: #000;
}

.singleProduct__description {
    margin: 20px 0 30px;
    font-size: 18px;
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif;
}

.singleProduct__description * {
    text-align: left !important;
    font-weight: 400 !important;
    font-family: 'Montserrat', sans-serif;
}

.singleProduct__list {
    list-style-type: disc;
    margin-left: 0;
    padding-left: 20px;
    font-size: 18px;
    font-style: italic;
}

.marginTop10 {
    margin-top: 10px;
}

.button--addToCart {
    background: #000;
    color: #fff;
    font-size: 18px;
    font-style: normal !important;
    text-transform: none !important;
    border: 2px solid #000;
    width: 340px;
    height: 50px;
    border-radius: 50px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .4s all ease-in-out;
}

.singleProduct .button--addToCart {
    height: 50px;
    border-radius: 50px;
}

.priceSection .button--addToCart {
    width: 260px;
    font-size: 20px;
    height: 55px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.priceSection .button--addToCart:hover {
    transform: translateY(-2px);
}

.button--addToCart__img {
    width: 30px;
    height: auto;
    margin-left: 20px;
    filter: brightness(0) invert(1);
    transition: .4s all ease-in-out;
}

.button--addToCart:hover .button--addToCart__img,
.button--addToCart:active .button--addToCart__img {
    transform: translateX(10px);
}

.button--addToCart:hover {
    color: #fff;
    background: #000;
}

.button__link--smaller {
    font-size: 17px;
}

.singleProduct__parts {
    font-style: italic;
    margin: 30px 0 20px 0;
}

.singleProduct__bottom {
    padding: 0;
    margin: 20px auto;
    overflow: hidden;
}

.singleProduct__bottom__btn {
    width: 100% !important;
    height: 45px !important;
    border-radius: 4px;
    background: #000;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
}

.singleProduct__longDesc {
    background: #F9F9F9;
    border: 2px solid #000;
    padding: 20px;
    margin: 20px 0;
    font-size: 15px;
    width: 100%;
    transition: .4s all;
    font-style: italic;
}

.arrowDown {
    margin-left: 20px;
    width: 20px;
    height: auto;
    filter: brightness(0) invert(1);
    transition: .4s all;
}

.rotate180 {
    transform: rotate(180deg);
}

.o-0 {
    opacity: 0;
    height: 0;
    transition: .4s all;
}

.__react_component_tooltip {
    font-style: italic;
    font-family: 'Montserrat', sans-serif;
}

/* Cart */
.cartContent {
    min-height: 75vh;
    margin-top: 150px;
    padding: 40px clamp(10%, calc((100vw - 1400px) / 2), 10%);
    background: #f9f9f9;
}

.cart__item__icon {
    width: 17px;
}

.cart__header {
    font-family: 'Playfair display', cursive;
    font-size: 26px;
    margin-top: 20px;
}

.emptyCart {
    margin: 60px 0;
}

.button--emptyCart {
    width: 250px;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 0;
    background: #000;
}

.button--emptyCart__link {
    font-size: 15px;
    color: #fff;
}

.button--emptyCart

.cart__header {
    font-size: 24px;
    margin: 30px 0;
}

.cart {
    margin-top: 20px;
    padding: 25px 40px;
    background: #fff;
    border: 2px solid #F1F1F1;
}

.cart__item__value--button {
    background: transparent;
    margin: 0 4px;
}

.cart__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    padding: 30px 0;
    transition: .5s all ease-in-out;
}

.cart__item__imgWrapper {
    width: 150px;
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__item__img {
    width: 100%;
    height: auto;
}

.cart__item__column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    flex: 1;
}

.firstCol {
    flex: 2;
    padding-left: 50px;
}

.cart__item__label {
    color: #757575;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
    text-transform: uppercase;
}

.cart__item__value {
    font-size: 15px;
    font-style: italic;
    font-weight: 500;
    text-align: center;
}

.cart__item__input {
    width: 50px;
    height: 30px;
}

.cart__item__value--flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart__summary {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.cart__summary__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cart__summary__header__label {
    font-size: 18px;
    margin-right: 20px;
    font-style: italic;
}

.cart__summary__header__value {
    font-size: 32px;
    font-style: italic;
}

.cart__summary__button {
    background: #000;
    color: #fff;
    border-radius: 50px;
    text-transform: uppercase;
    margin-top: 30px;
    width: 300px;
    height: 50px;
    font-size: 15px !important;
    font-weight: 700;
    transition: .3s all ease-in-out;
    border: 3px solid #000;
}

.cart__summary__button:hover {
    transform: translateY(-3px);
}

.cart__summary__button--shippingAndPayment {
    width: 400px;
}

.cart__mobileColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Add to cart modal */
.ReactModal__Overlay {
    background: rgba(0, 0, 0, .7) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.ReactModal__Content {
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: center !important;
}

/* React modal - animation */
.smallModal {
    transition: .4s all;
}

.smallModal .ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100vw);
    transition: all 500ms ease-in-out;
}

.smallModal .ReactModal__Content {
    transform: scale(0) translate(-50%, -50%);
    transition: all 500ms ease-in-out;
    transform-origin: center !important;
    transition-delay: 500ms !important;
}

.smallModal .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
    transition: all 500ms ease-in-out;
}

.smallModal .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.smallModal .ReactModal__Content--after-open {
    transform: scale(1) translate(-50%, -50%);
    transition: all 500ms ease-in-out;
}

/* End */

.smallModal .ReactModal__Content {
    width: 500px;
    height: 500px;
}

.modalHeader {
    font-size: 24px;
    margin: 60px 0 30px;
    text-align: center;
}

.modalHeader--smaller {
    font-size: 18px;
    margin-bottom: 50px;
    padding: 0 30px;
}

.modalTick {
    width: 100px;
    height: auto;
}

.modalClose {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: auto;
    background: transparent;
}

.modalClose__img {
    width: 100%;
    height: auto;
}

.modal__btn {
    font-weight: 500;
    margin: 20px;
    height: 40px;
    border-radius: 40px;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    text-transform: uppercase;
    padding: 8px 15px;
    transition: .4s all ease-in-out;
}

.modal__btn:hover {
    transform: translateY(-3px);
}

/* Shipping and payment */
.shippingAndPayment * {
    zoom: .95;
}

.cart--flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.couponSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.couponSection .ribbonLabel {
    width: 100%;
    flex-basis: 60%;
    flex-grow: 0;
    flex-shrink: 0;
}

.button--coupon {
    width: 30%;
    height: 50px;
    background: #000;
    color: #fff;
    border-radius: 4px;
    transition: .4s all;
}

.button--coupon:hover {
    transform: translateY(-3px);
}

.address, .couponUsed {
    font-style: italic;
    margin-left: 40px;
    font-size: 14px;
    font-weight: 400;
}

.shippingAndPayment--error {
    border: 2px solid red !important;
}

.shippingAndPayment__section {
    width: 48%;
}

.shippingAndPayment__header {
    font-size: 18px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
}

.shippingAndPayment__form {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.label-100 {
    width: 100%;
}

.label-50 {
    width: 49%;
}

.label-70 {
    width: 69%;
}

.label-60 {
    width: 59%;
}

.label-30 {
    width: 29%;
}

.label-25 {
    width: 24%;
}

@media(max-width: 768px) {
    .label-25 {
        width: 100%;
    }
}

.label-20 {
    width: 18%;
}

.shippingAndPayment__input {
    width: 100%;
    background: #fff;
    border: 1px solid #000;
    height: 50px;
    padding: 5px 10px;
    margin: 8px 0;
    transition: .3s all ease-in-out;
}

.shippingAndPayment__input[disabled] {
    opacity: .5;
    border: 1px solid #000 !important;
}

.shippingAndPayment__textArea {
    width: 100%;
    height: 75px;
    padding: 20px 15px;
    border: 1px solid #000;
    resize: none;
    margin-top: 10px;
}

.ribbonBtnLabel {
    margin: 20px 0 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-style: italic;
}

.ribbonBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    background: transparent;
    border: 1px solid #000;
    position: relative;
}

.hour--disabled .ribbonBtn {
    cursor: default;
}

.address--1 a {
    text-decoration: underline;
}

.ribbonBtn--red {
    border: 2px solid red;
}

.d-none {
    display: none;
}

.ribbon {
    width: 10px;
    height: 10px;
    background: #000;
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ribbonDedication {
    display: block;
    opacity: 1;
    transition: .4s all;
}

.o-none {
    transition: .4s all;
    opacity: 0;
    height: 0;
    overflow: hidden;
}

.ribbonLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ribbonLabel .ribbonBtn {
    margin-left: 30px;
}

.cart__summary--shippingAndPayment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 40px;
}

.cart__summary__button--back {
    background: #000;
    color: #fff;
}

.cart__summary__button--back a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: transparent;
}

.shippingAndPayment__calendar {
    margin-top: 30px;
    max-width: 750px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(6, 1fr);
    justify-content: flex-start;
    align-items: flex-start;
}

.shippingAndPayment__calendar__btn {
    display: none;
    width: calc(100% / 6 - 20px);
    height: 80px;
    background: transparent;
    border: 1px solid #000;
    border-radius: 4px;
    margin-bottom: 20px;
    min-width: 70px;
    margin-right: 20px;
    transition: .4s all ease-in-out;
}

.shippingAndPayment__calendar__btn * {
    color: #000;
}

.shippingAndPayment__calendar__btn:hover *,
.shippingAndPayment__calendar__btn:hover {
    color: #fff !important;
}

.shippingAndPayment__calendar__btn:hover {
    background: #000;
}

.calendarDay {
    font-size: 20px;
    text-align: center;
    font-style: italic;
}

.calendarMonth {
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    font-style: italic;
    padding-top: 5px;
}

.shippingAndPayment__calendar__btn--checked {
    background: #000;
    color: #fff !important;
}

.shippingAndPayment__calendar__btn--checked * {
    color: #fff !important;
}

.shippingAndPayment__section--hours {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-auto-flow: column;
    width: 100%;
    margin-top: 30px;
}

.ribbonBtnLabel--hour {
    margin: 10px 0;
}

.ribbonBtnLabel--fastest {
    margin: 20px 0 0;
}

.ribbonBtnLabel--chooseHour {
    display: none;
}

.hour--disabled {
    opacity: .3;
    user-select: none;
    cursor: default;
}

/* TY page */
.tyPage {
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ty__header {
    font-size: 36px;
}

.ty__subheader {
    font-size: 28px;
}

.ty__header, .ty__subheader {
    text-align: center;
    padding: 20px;
}

.button--ty {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 40px;
    transition: .4s all;
}

/* Button animations */
.marginLeft15 {
    margin-left: 15px;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0 150px;
}

/* Blog page */
.blogContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 80px 0;
}

.blogContent__item {
    width: 30%;
    background: #F9F9F9;
    border: 1px solid #000;
    padding: 20px;
    margin-bottom: 50px;
}

.blogContent__item__imgWrapper {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.blogContent__item:hover .blogContent__item__img {
    transform: scale(1.2);
}

.blogContent__item__img {
    width: 100%;
    transition: .4s all ease-in-out;
}

.blogContent__item__title {
    margin-top: 20px;
    position: relative;
    font-style: italic;
}

.blogContent__item__title::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 50px;
    height: 5px;
    background: #D6B04A;
}

.blogContent__item__excerpt {
    margin-top: 40px;
    text-align: justify;
    font-size: 14px;
    font-style: italic;
}

/* About us */
.aboutUs__section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    margin: auto;
    max-width: 90vw;
}

.aboutUs__section:first-of-type {
    padding-top: 0;
}

.aboutUs__img {
    width: 35%;
    height: auto;
}

.aboutUs__content {
    width: 60%;
}

.aboutUs__header {
    font-family: 'Playfair display', cursive;
    font-size: 48px;
    font-weight: 500;
    margin: 0 auto -20px;
    text-align: center;
}

.aboutUs {
    margin-top: -60px;
}

.aboutUs__p {
    font-size: 15px;
    font-style: italic;
    margin: 12px 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.textAlignRight * {
    text-align: right;
}

/* Single post */
.singlePost__title {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    text-align: left;
    width: 100%;
    margin: 40px 0;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
}

.singlePost__content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.singlePost__article {
    width: 70%;
}

.singlePost__article * {
    font-style: italic;
}

.singlePost__aside {
    width: 20%;
}

.singleProduct--post {
    padding: 200px clamp(10%, calc((100vw - 1400px) / 2), 10%) 50px;
    position: relative;
}

.singlePost__aside__header {
    text-align: right;
    padding-bottom: 15px;
    font-family: 'Playfair display', cursive;
    border-bottom: 3px solid rgba(0, 0, 0, .25);
}

.singlePost__aside__list__item {
    text-align: right;
    margin: 15px 0;
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    font-style: italic;
    transition: .2s all ease-in-out;
}

.singlePost__aside__list__item:hover {
    transform: translateX(-10px);
}

/* Contact */
.contactContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto 30px;
}

.contactContent__img {
    width: 100%;
    height: auto;
}

.contactContent__section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
}

.contactContent__frame__header,
.contactContent__frame__text {
    font-size: 15px;
}

.contactContent__frame strong {
    font-family: 'Playfair display', cursive !important;
    color: #D6B04A !important;
    margin-bottom: 6px !important;
    display: block !important;
    font-size: 17px !important;
}

.contactContent__frame * {
    text-align: center !important;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}

.contactContent__frame {
    width: 48%;
}

.link--animated {
    display: block;
    transition: .4s all;
}

.link--animated:hover {
    transform: translateX(10px);
}

.offerContent--contact {
    margin-top: 200px;
}

/* Pages */
.pageContent {
    margin: 50px 0 80px;
}

/* Banquet section */
.section--banquet {
    margin: 20px 0;
}

.section--banquet__select, .section--banquet__select option {
    margin-left: 15px;
    width: 50px;
    height: 40px;
    padding-left: 5px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.banquet__product__title {
    background: #000;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    font-size: 14px;
    flex-basis: 50%;
    border-radius: 4px;
}

.banquet__select--amount {
    flex-basis: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: -10px;
}

.banquet__select--amount .section--banquet__select {
    margin-left: 0;
    height: 25px;
    margin-top: 5px;
}

.banquet__btn {
    flex-basis: 15%;
}

.label--banquet {
    display: block;
    margin: 30px 0;
}

.row--banquetFirst .label--banquet {
    width: 100%;
    margin-bottom: 5px;
}

.singleProduct__left--banquet {
    width: 25%;
}

.singleProduct__right--banquet {
    width: 60%;
}

.btn--disabled {
    opacity: .5;
}

.banquet__hiddenSection {
    margin: 30px 0;
    height: auto;
    opacity: 1;
    transition: .4s all ease-in-out;
    border-bottom: 1px solid #8c8f94;
}

.banquet__product:last-of-type .banquet__hiddenSection {
    border-bottom: none;
}

.banquet__hiddenSection .singleProduct__price {
    margin-left: 0;
}

.singleProduct__price--small {
    font-weight: 400;
    font-size: 15px;
    margin-right: 25px;
}

.banquet__hiddenSection--hidden {
    height: 0;
    opacity: 0;
    overflow: hidden;
}

.cart__item__banquetWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 30px;
}

.cart__item__banquet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .25);
    margin-bottom: 0 !important;
}

.cartBanquet__header {
    text-align: center;
    margin-top: 30px;
    font-family: 'Playfair display', Serif;
}

.cart--borderRed {
    border: 1px solid red;
}

.banquet__product:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, .25);
}

.button--hide {
    background: #000;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0 30px;
    color: #fff;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: .75;
    transition: .4s all;
}

.button--hide__img {
    transform: rotate(180deg);
    margin-left: 15px;
    filter: brightness(0) invert(1);
}

.button--hide:hover {
    opacity: 1;
}

.marginTop50 {
    margin-top: 50px;
}

/* Animations */
.shakeAnimation {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    animation-delay: .5s;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.opacity-0 {
    opacity: 0 !important;
    z-index: -1000 !important;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

/* Update - 10.08.2021 */
.afterFormSection {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.routeSection {
    margin-top: 20px;
}

.route {
    font-weight: 400;
    margin: 5px auto;
    text-align: center;
}

.route--error {
    color: #d83b27;
}

.route--succes {
    color: #12944b;
}

.loaderSpan {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderSpan > * {
    margin: 15px auto 0;
    display: block;
    zoom: .5;
}

.routeSection__btn {
    display: block !important;
    margin-bottom: 15px;
    margin-top: 0;
}

.address {
    font-weight: 400;
}

.cart__summary__header--sum {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, .5);
}

.opacity-5 {
    position: relative;
    opacity: .5;
}

.shippingAndPayment__calendar__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: .5;
    z-index: 2;
}

.pageContent, .pageContent * {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px !important;
    font-style: italic;
}

.extraInputs {
    width: 60%;
}

#d-none {
    display: none;
}

.ReactModalPortal {
    position: relative;
    z-index: 10001;
}

.extraInputs .ribbonBtnLabel .address {
    margin-left: 0;
    font-size: 15px;
}

.noDelivery {
    font-size: 14px;
    line-height: 42px;
    font-weight: 400;
    vertical-align: middle;
}

/* SCALING */
*:not(.singleProduct, .singleProduct *, .App, html, head,.topBar, .topBar *,
#root, body, .topMenu, .topMenu *) {
    zoom: .96;
}

.singleProductPage, .singleProductPage * {
    zoom: unset !important;
}

.slider, .slider *:not(button) {
    zoom: 1 !important;
}

@media(min-width: 1200px) {
    .landing__logo {
        width: 350px !important;
    }

    .landing__header {
        font-size: 36px !important;
        margin: 40px 0 !important;
    }

    .landing .button--landing {
        width: 360px !important;
        height: 65px !important;
    }

    .landing .button--landing__link {
        font-size: 18px !important;
    }

    .landing__content {
        right: 15%;
    }
}

@media(-webkit-max-device-pixel-ratio: 1.05) {
    *:not(.singleProduct, .singleProduct *, .App, html, head,.topBar, .topBar *,
#root, body, .topMenu, .topMenu *) {
        zoom: 1;
    }

    @media(min-width: 1200px) {
        .landing__logo {
            width: 380px !important;
        }

        .landing__header {
            font-size: 52px !important;
            margin: 40px 0 !important;
        }

        .landing .button--landing {
            width: 400px !important;
            height: 65px !important;
        }

        .landing .button--landing__link {
            font-size: 18px !important;
        }

        .landing__content {
            right: 10%;
        }
    }
}

.shopClosedText {
    text-align: center;
    font-style: italic;
    font-size: 14px;
    margin-top: 20px;
    color: #941212;
}

.shopClosedText a {
    font-weight: 700;
}

.shippingAndPayment .shopClosedText {
    margin-top: 40px;
    max-width: 80%;
    text-align: left;
    font-size: 18px;
}

.homePageSection .button--landing {
    margin-top: 50px;
}

.paymentAtDelivery {
    margin-left: 30px;
}

.cart__input {
    width: 40px;
    height: 30px;
    text-align: center;
}

/* Categories */
.categories {
    display: flex;
    justify-content: center;
    align-self: center;
}

.categories__item {
    margin: 20px 10%;
}

.categories__item__img {
    max-width: 100%;
}

.footer__menu__item__img {
    max-width: 20px;
}

.callToAction {
    background: #000;
    width: 150px;
    height: 28px;
    border-radius: 28px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    transition: .2s all;
}

.callToAction:hover {
    opacity: .85;
}

.offerContent--offer .offerContent__header {
    text-align: left;
}

/* Slider */
.slider {
    margin-top: -15px;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}

.slider > div > div,
.slider > div {
    height: 100%;
}

.slider__item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.slider__item__img {
    width: 100%;
    height: auto;
}

.slider__item *,
.sliderBottom__item * {
    color: #fff;
    text-align: center;
}

.sliderBottom__item {
    /*max-height: 210px;*/
    position: relative;
}

.slider__item__header {
    font-size: 48px;
    font-weight: 400;
    font-family: 'Playfair display', serif;
}

.slider__item__subheader {
    font-weight: 400;
    margin: 20px auto;
    font-size: 19px;
    font-family: 'Montserrat', sans-serif;
}

.slider__item__btn {
    width: 300px;
    height: 45px;
    border-radius: 45px;
    background: #000;
    text-transform: uppercase;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-top: 5px;
    z-index: 10;
    opacity: 1;
    font-family: 'Montserrat', sans-serif;
    transition: .2s all ease;
}

.slider__item__btn:hover {
    transform: translateY(-2px);
}

.sliderBottom {
    display: grid;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 100px;
}

.landing img {
    filter: brightness(.75);
}

.sliderBottom__item__img {
    width: 100%;
    height: auto;
}

.slider__item__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.afterSlider {
    margin-top: 100px;
}

.afterSlider__header {
    font-family: 'Playfair display', serif;
    text-align: center;
    font-size: 36px;
    margin: 20px auto;
    font-weight: 500;
    max-width: 900px;
}

.slider__item__btn--afterSlider {
    height: 60px;
    margin: 40px auto;
    font-size: 17px;
    width: 450px;
    max-width: 100%;
    border-radius: 50px;
}

.sliderArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    width: 30px;
    height: auto;
    z-index: 5;
}

.sliderArrow .btn__img {
    width: 100%;
    transition: .2s all;
}

.sliderArrow--prev .btn__img {
    transform: rotateY(180deg);
}

.sliderArrow--prev {
    left: 20px;
}

.sliderArrow--next {
    right: 20px;
}

.sliderArrow:hover .btn__img {
    filter: brightness(1);
}

.sliderBottom__item__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10%;
    background: rgba(0, 0, 0, .5);
    opacity: 0;
    overflow: hidden;
    transition: .2s all ease-in-out;
}

.sliderBottom__item__imgWrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.sliderBottom__item__img {
    transition: .2s all ease-in-out;
}

.sliderBottom__item:hover .sliderBottom__item__img {
    transform: scale(1.05);
}

.sliderBottom__item:hover .sliderBottom__item__content {
    opacity: 1;
}

.sliderBottom__item__content * {
    color: #fff;
    text-align: center;
}

.sliderBottom__item__content__header {
    font-family: 'Playfair display', serif;
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 21px;
}

.sliderBottom__item__content__text {
    font-size: 14px;
}

.panelContent__frame__header--flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addProduct__input--textarea {
    resize: none;
    height: 80px !important;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

@media(min-width: 1200px) {
    .afterLanding {
        margin-top: 100px;
    }
}

.afterLanding__header {
    font-family: 'Playfair display', serif;
    font-weight: 500;
    text-align: center;
    font-size: 36px;
}

.priceSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.offerContent__grid--landing .offerContent__item__header {
    text-align: center;
}

.button--aboutUsPage {
    width: 100%;
    max-width: 380px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    height: 60px;
    font-style: normal;
    font-family: 'Montserrat', sans-serif;
    border-radius: 60px;
}

.singleProduct--circle {
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 50% !important;
    margin: 0 9px 0 0 !important;
    padding: 0 !important;
}

.singleProduct--amount {
    font-size: 20px;
    font-weight: 400;
    color: #000 !important;
    border-width: 2px;
}

.allergensHeader {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
}

.offerContent__item__price,
.topBar .footer__header {
    font-family: 'Montserrat', sans-serif;
}

.offerContent__item__header {
    font-weight: 500;
    font-size: 40px;
    margin-top: -5px;
    font-family: 'Playfair display', cursive;
}

.footer__header__link,
.footer__menu__item__link {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}

@media(min-width: 1200px) {
    .singleProduct__title {
        margin-top: -10px;
    }
}

.singleProduct__parts *,
.singleProduct__parts {
    font-style: normal;
    font-family: 'Montserrat', sans-serif;
}

.singleProduct__parts strong {
    font-size: 15px;
    text-transform: uppercase;
}

.contactContent *,
.button--ty,
.pageContent * {
    font-style: normal !important;
}

.singleProduct__options__buttons--sizes .singleProduct__options__btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.singleProduct__oneLine {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.singleProduct__oneLine .singleProduct__options--amount {
    margin-left: 25px;
}

.singleProduct__options--sizes .singleProduct__options__btn {
    font-size: 18px;
    padding: 0;
}

.afterFormSection .extraInputs {
    width: 100%;
}

@media(-webkit-min-device-pixel-ratio: 1.05) {
    .singleProduct__title {
        font-size: 32px;
    }

    .singleProduct__description,
    .singleProduct__description * {
        font-size: 15px;
    }

    .singleProduct__price {
        font-size: 24px;
    }

    .priceSection .button--addToCart {
        width: 240px;
        height: 45px;
        font-size: 16px;
    }

    .singleProduct__options--sizes .singleProduct__options__btn {
        font-size: 15px;
    }

    .singleProduct__parts * {
        font-size: 14px;
    }

    .allergensHeader {
        font-size: 13px;
    }

    .singleProduct__options__buttons--sizes .singleProduct__options__btn,
    .singleProduct--circle {
        width: 40px;
        height: 40px;
    }
}

.panelContent__column__value--hours .panelContent__column__btn {
    width: 30px;
    height: 30px;
    border: 1px solid #000;
}

.panelContent__column__btn--checked {
    position: relative;
}

.panelContent__column__btn--checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 60%;
    background: #000;
}

.btn--hoursChange {
    width: 100%;
    margin-top: 30px;
    height: 50px;
    color: #fff;
    background: #000;
    font-size: 18px;
    font-weight: 700;
    transition: .2s all;
}

.btn--hoursChange:hover {
    background: #121212;
}

.o-5 {
    opacity: .5;
}

.ribbonBtnLabel--disabled {
    opacity: .5;
    cursor: default;
}

.noSlotsAvailable {
    margin-top: 20px;
    font-style: italic;
    font-size: 17px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 110vh;
    height: 100vh;
    background: rgba(0, 0, 0, .8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
    zoom: unset !important;
}

.modal__closeBtn {
    background: transparent;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    outline: none;
    cursor: pointer;
}

.modal > .img {
    width: 600px;
    height: auto;
    max-width: 90%;
}

.cart__summary__loader {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.startPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 999999;
}

.startPage,
.startPage * {
    zoom: 1 !important;
}

.startPage__img--mobile {
    display: none;
}

.startPage__img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 150%;
    min-width: 100%;
    height: auto;
    z-index: 2;
}

.startPage__inner {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 3;
    background: rgba(0, 0, 0, .65);
    display: flex;
    flex-direction: column;
    gap: 32px;
    justify-content: center;
    align-items: center;
}

.startPage__inner__logo {
    width: 80%;
    max-width: 250px;
    filter: brightness(0) invert(1);
}

.startPage .topMenu {
    display: none !important;
}

.startPage__header {
    color: #fff;
    font-weight: 700;
    text-align: center;
}

.startPage__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.startPage__buttons > .slider__item__btn {
    background: #fff;
    color: black;
    height: 50px;
    border-radius: 50px;
    min-width: 280px;
    border: 3px solid white;
}

.startPage__buttons > .slider__item__btn--selected {
    background: black;
    color: white;
    border: 3px solid black;
}

.input--masked {
    text-align: center;
    height: 50px;
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    outline: none !important;
}

.text-red, .text-green {
    font-size: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    margin: 5px auto 0;
    font-weight: 700;
}

.text-red {
    color: red;
}

.text-green {
    color: #76FF03;
}

.startPage__map {
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 999;
    background: rgba(0, 0, 0, .7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.startPage__map__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 36px;
    font-weight: 700;
    background: transparent;
    border: none;
}

.btn--showMap {
    transition: .2s all ease-in-out;
    background: white;
    height: 60px;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 15px;
    width: 450px;
    max-width: 80vw;
}

.btn--showMap--small {
    background: black;
    color: white;
    width: 200px;
}

.flex-gap {
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
}

.flex-gap-8 {
    gap: 8px !important;
}

.zipCodeResponse {
    max-width: 100%;
    min-height: 120px;
}

.btn--showMap:hover {
    transform: translateY(-5px);
}

.modal, .modal * {
    zoom: 1 !important;
}

.modal__inner {
    padding: 30px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background: #fff;
    max-width: 500px;
    width: 90%;
}

.modal__inner__text {
    text-align: center;
    font-size: 18px;
}

.modal__inner .btn--showMap--small {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: none;
    font-weight: 700;
    text-transform: uppercase;
}

.nightDeliveryHeader {
    margin: 30px 0 20px;
    font-weight: 700;
    font-size: 18px;
}

.nightDeliverySubheader {
    font-size: 15px;
    font-weight: 400;
}

.nightDeliverySubheader--2 {
    margin-top: 20px;
    font-weight: 700;
}

.text-green-admin {
    color: green !important;
    font-weight: 700 !important;
}

.contactForm {
    max-width: 1000px;
    margin: 0 auto 50px;
}

.shippingAndPayment__textArea--contactForm {
    height: 100px;
}

.contactForm__btn {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: white;
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
    text-transform: uppercase;
    border-radius: 50px;
}

.contactForm__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 15px;
}

.contactForm__success {
    text-align: center;
    padding-top: 15px;
    width: 100%;
}

.shippingAndPayment__calendar__btn:nth-child(1),
.shippingAndPayment__calendar__btn:nth-child(2),
.shippingAndPayment__calendar__btn:nth-child(3),
.shippingAndPayment__calendar__btn:nth-child(4),
.shippingAndPayment__calendar__btn:nth-child(5),
.shippingAndPayment__calendar__btn:nth-child(6),
.shippingAndPayment__calendar__btn:nth-child(7),
.shippingAndPayment__calendar__btn:nth-child(8),
.shippingAndPayment__calendar__btn:nth-child(9),
.shippingAndPayment__calendar__btn:nth-child(10),
.shippingAndPayment__calendar__btn:nth-child(11),
.shippingAndPayment__calendar__btn:nth-child(12) {
    display: block !important;
}

.shippingAndPayment__calendar__btn--notAvailable {
    /*opacity: .5;*/
}

.btn--newYearsEve {
    width: 100%;
    height: 45px;
    border-radius: 45px;
    background: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 24px;
    transition: .4s all ease-in-out;
}

.btn--newYearsEve:hover {
    transform: translateY(-5px);
}

.shippingAndPayment__calendar--newYearsEve {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.btn--backToCalendar {
    background: transparent;
    color: #000;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 500;
    border: none;
    margin-right: 48px;
}

.ribbonBtn[disabled] {
    opacity: .5;
    cursor: default;
}

.shippingAndPayment__calendar__btn--special {
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60px;
}

.shippingAndPayment__calendar__btn--special * {
    font-weight: 700 !important;
}

@media(max-width: 996px) {
    .shippingAndPayment__calendar__btn--special {
        max-width: 250px;
    }

    .shippingAndPayment__calendar__btn--special * {
        font-size: 12px !important;
    }
}
