@media(max-width: 1800px) {
    .afterLanding {
        width: 90%;
        margin: auto;
    }
}

@media(max-width: 1600px) {
    .singleProduct--img {
        transform: scale(0.7);
    }

    .offerContent__item__header {
        font-size: 32px;
    }

    .footer__paymentBanner {
        margin-left: 3vw;
        width: 25%;
    }
}

@media(max-width: 1400px) {
    .sliderBottom__item__content__header {
        font-size: 15px;
        margin-bottom: 12px;
    }

    .offerContent__item__header {
        font-size: 28px;
    }

    .sliderBottom__item__content__text {
        font-size: 12px;
    }

    .singleProduct__options__buttons--sizes .singleProduct__options__btn,
    .singleProduct--amount,
    .singleProduct--circle {
        width: 40px;
        height: 40px;
        padding: 0;
    }

    .singleProduct__options__buttons {
        margin-left: 10px;
    }

    /* Top menu */
    .topMenu__list__item {
        padding: 0 15px;
        margin: 0 5px;
    }

    .topMenu__list__item__link {
        font-size: 16px;
    }

    /* Landing */
    .landing__logo {
        width: 300px;
    }

    .landing__header {
        font-size: 48px;
    }

    /* Home page section */
    .homePageSection__item__header {
        font-size: 24px;
    }

    .button--homePageSection {
        width: 250px;
        height: 60px;
    }

    .button--homePageSection__link {
        font-size: 18px;
    }

    /* Offer */
    .offerContent__grid {
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 100px;
        grid-row-gap: 150px;
    }

    /* Single product */
    .singleProduct--img {
        display: none;
    }

    /* Admin */
    .panelMenu__list__item__link,
    .panelMenu__top__header,
    .panelMenu__top__subheader,
    .panelMenu__list__subitem
    {
        font-size: 13px;
    }

    .panelMenu__list__item__img,
    .panelMenu__list__subitem__img
    {
        width: 15px;
    }

    .panelContent__start__item__img {
        width: 40px;
    }

    .panelContent__header__h {
        font-size: 20px;
    }

    .panelContent__start__item__header,
    .panelContent__header__back
    {
        font-size: 15px;
    }

    .panelContent__header__back__img {
        width: 25px;
    }

    .panelContent__filters {
        align-items: flex-start;
        flex-direction: column;
    }

    .panelContent__orderDetails {
        flex-wrap: wrap;
    }

    .panelContent__clientData,
    .panelContent__shipping {
        width: 100%;
        margin-right: 0;
    }

    .panelContent__orderStatus {
        margin: 40px 0;
    }

    .panelContent__buttons .btn--red {
        margin-right: 0;
    }

    .panelContent__cart__column:not(:last-child) {
        padding: 0 10px;
    }

    .panelContent__cart__content {
        margin-left: 0;
    }

    /* Shipping and payment */
    .shippingAndPayment__calendar {
        display: flex;
        flex-wrap: wrap;
    }
}

@media(max-width: 1200px) {
    .offerContent__grid {
        grid-column-gap: 30px;
        grid-row-gap: 50px;
    }

    .afterSlider__header {
        font-size: 24px;
    }

    .singleProduct__oneLine {
        flex-direction: column;
        align-items: flex-start;
    }

    .singleProduct__oneLine .singleProduct__options--amount {
        margin-left: 0;
    }

    /* Top menu */
    .topMenu__list__item {
        padding: 0 12px;
        margin: 0 5px;
    }

    .topMenu__list__item__link {
        font-size: 14px;
    }

    .topMenu__languages {
        margin-left: 15px;
    }

    /* Landing */
    .landing__logo {
        width: 270px;
    }

    .landing__header {
        font-size: 32px;
        margin: 40px 0;
    }

    .button--landing {
        width: 320px;
        height: 55px;
    }

    .button--landing__link {
        font-size: 18px;
    }

    /* Home page section */
    .button--homePageSection {
        width: 250px;
        height: 60px;
    }

    .homePageSection__text {
        font-size: 18px;
    }

    /* Footer */
    .footer__menu, .footer__list {
        padding-left: 10px;
    }

    .footer__menu__item {
        margin: 0 8px;
    }

    /* Offer */
    .offerContent__item__header {
        font-size: 24px;
    }

    .offerContent__item__price {
        font-size: 19px;
    }

    /* Cart */
    .cart__item__label, .cart__item__value {
        font-size: 13px;
    }

    .cart__item__column {
        padding: 0 15px;
    }

    /* Admin */
    .panelMenu__top__logoWrapper {
        display: none;
    }

    .panelContent__item {
        flex-wrap: wrap;
    }

    .orderItem .panelContent__column {
        flex-basis: 30%;
        margin-bottom: 30px;
    }

    .panelContent__column__label {
        margin-bottom: 7px;
    }

    .addProduct__form--addProduct {
        flex-wrap: wrap;
    }

    .addProduct__form--addProduct > * {
        width: 100% !important;
    }

    .addProduct__form__subsection--marginLeft {
        margin-left: 0;
    }

    /* Blog */
    .blogContent__item__title {
        font-size: 22px;
    }

    .blogContent__item {
        width: 45%;
    }

    /* About us */
    .aboutUs__section {
        padding: 70px 0;
    }

    .aboutUs__img {
        width: 45%;
    }

    .aboutUs__content {
        width: 50%;
    }

    .aboutUs__header {
        font-size: 20px;
    }

    .aboutUs__p {
        font-size: 14px;
    }

    /* Contact */
    .contactContent__frame__header,
    .contactContent__frame__text {
        font-size: 13px;
    }

    /* Banquet menu */
    .singleProduct__right__header {
        margin-top: 30px;
        text-align: center;
    }

    .singleProduct--banquet .singleProduct__options {
        margin-bottom: 50px;
    }

    .singleProduct__right--banquet {
        width: 100%;
    }

    .singleProduct--banquet {
        justify-content: flex-start;
    }
}

@media(max-width: 992px) {
    /* Global */
    .d-mobile {
        display: block;
    }

    .d-desktop {
        display: none;
    }

    .d-desktop-important {
        display: none !important;
    }

    .d-mobile-important {
        order: 3;
        display: block !important;
        margin-bottom: 30px;
    }

    .footer {
        height: auto;
    }

    .footer__paymentBanner {
        margin: 30px 0 0 0;
        width: 100%;
        max-width: 400px;
    }

    .aboutUs {
        margin-top: 0;
    }

    .aboutUs__header {
        font-size: 28px;
    }

    .categories {
        flex-direction: column;
    }

    .topMenu__list__item__link {
        font-size: 26px;
    }

    .mobileMenu__list li {
        margin-bottom: 20px;
    }

    .offerContent__header {
        margin-bottom: 30px;
    }

    /* Top menu */
    .topMenu {
        padding: 0 5%;
        height: 70px;
    }

    .topMenu__logo {
        width: 50px;
    }

    .button--hamburger {
        background: transparent;
        position: relative;
        z-index: 3;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url("../img/hamburger.png");
        background-size: 100% 100%;
        cursor: pointer;
    }

    .hamburgerBtn__img {
        width: 100%;
        height: auto;
    }

    /* Landing */
    .landing {
        margin-top: 70px;
    }

    /* Home page section */
    .homePageSection__menu {
        flex-direction: column;
        width: 90%;
    }

    .homePageSection__item {
        flex-direction: column;
        padding: 20px 0;
    }

    .homePageSection__imgWrapper {
        width: 90%;
        max-width: 400px;
        margin: 20px 0 0;
        order: 2 !important;
    }

    .homePageSection__text {
        order: 1 !important;
        width: 100%;
        margin: 0 !important;
        text-align: center !important;
    }

    .homePageSection .button--landing {
        display: block;
        margin-top: 20px;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .homePageSection__item__content {
        margin: 0 0 10px !important;
        width: 90%;
        max-width: 450px;
    }

    /* Footer */
    .footer {
        flex-direction: column;
        padding: 20px;
    }

    .footer__menu, .footer__list {
        padding: 0;
    }

    .footer__header {
        text-align: center;
    }

    .footer__header:last-child {
        position: relative;
        right: 0;
        top: 0;
        transform: none;
    }

    /* Offer */
    .offerContent {
        margin-top: 150px;
    }

    .offerContent__header {
        font-size: 26px;
    }

    .offerContent__grid {
        margin-top: 90px;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 80px;
    }

    /* Single product */
    .singleProduct {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 150px;
    }

    .singleProduct__title {
        font-size: 18px;
    }

    .singleProduct__left {
        width: 40%;
    }

    .singleProduct__description, .singleProduct__list {
        font-size: 14px;
    }

    .singleProduct__mainImage {
        height: 400px;
    }

    /* Cart */
    .cartContent {
        margin-top: 130px;
    }

    .cart__item__label {
        font-size: 12px;
        margin-bottom: 4px;
    }

    .cart__item {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 12px;
    }

    .cart__item__imgWrapper {
        grid-column: 1;
        grid-row: 1 / span 3;
    }

    .firstCol {
        grid-column: 2;
        grid-row: 1;
    }

    .secondCol {
        grid-column: 2;
        grid-row: 2;
    }

    .thirdCol {
        grid-column: 2;
        grid-row: 3;
    }

    .fourthCol {
        grid-column: 3;
        grid-row: 1;
    }

    .fifthCol {
        grid-column: 3;
        grid-row: 2;
    }

    .sixthCol {
        grid-column: 3;
        grid-row: 3;
    }

    .cart__summary__header__label {
        font-size: 18px;
    }

    .cart__summary__header__label--cart {
        font-size: 15px;
    }

    .cart__summary__header__value {
        font-size: 24px;
    }

    .cart__summary__button {
        font-size: 15px;
        max-width: 350px;
        width: 100%;
    }

    /* Shipping and payment */
    .cart--flex {
        flex-wrap: wrap;
    }

    .shippingAndPayment__section {
        width: 100%;
        margin-bottom: 20px;
    }

    .opacity-5 {
        margin: 10px;
    }

    /* Top menu */
    .topMenu__list__item__img {
        transform: translateY(5px);
    }

    .cartCounter {
        top: -5px;
    }

    .footer__menu--footer {
        position: relative;
        top: 40px;
        right: 0;
        transform: none;
    }

    .topBar {
        padding: 0 5vw;
    }

    .footer__menu--topBar {
        right: 5vw;
    }

    .offerContent__imgWrapper {
        margin-top: 20px;
    }

    /* Admin */
    .panel {
        display: block;
    }

    .panelMenu {
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: #000;
        padding: 10px 5%;
    }

    .panelMenu__menuMobile {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .panelContent {
        width: 100%;
        padding: 20px 5%;
        margin-left: 0;
        margin-top: 100px;
    }

    .panelMenu__menuMobile__hamburgerWrapper {
        background: transparent;
    }

    .panelMenu__menuMobile__hamburgerImg {
        width: 30px;
        height: auto;
        filter: brightness(0) invert(1);
    }

    .panelMenu__top__header,
    .panelMenu__top__subheader {
        color: #fff;
    }

    .panelContent__header__back,
    .panelContent__header__back:hover .panelContent__header__back__img
    {
        transform: none;
    }

    .panelContent__cart__column {
        font-size: 14px;
    }

    .panelContent__cart__item {
        padding-bottom: 15px;
    }

    .panelContent__cart__item:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, .25);
    }

    .panelMenu__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: #fff;
        z-index: 11;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .panelMenu__list {
        width: 100%;
    }

    .panelMenu__menuMobile__closeBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 30px;
        background: transparent;
    }

    .panelMenu__menuMobile__closeBtn__img {
        width: 100%;
        height: auto;
    }

    .addProduct__header, .addProduct__header__h {
        margin: 15px 0;
        font-size: 18px;
    }

    .panelContent__ribbons__column {
        font-size: 13px;
    }

    /* About us */
    .aboutUs__section {
        flex-direction: column;
        align-items: center;
        padding: 40px 0;
    }

    .aboutUs__img, .aboutUs__content {
        width: 100%;
    }

    .aboutUs__img {
        max-width: 600px;
        order: 1 !important;
    }

    .aboutUs__content {
        order: 2 !important;
        margin-top: 40px;
    }

    .aboutUs__header {
        text-align: center !important;
    }

    .aboutUs__p {
        text-align: left !important;
    }

    /* Single post */
    .singlePost__title {
        font-size: 18px;
    }

    .singlePost__article {
        width: 100%;
    }

    .singlePost__aside {
        display: none;
    }

    .singlePost__article p {
        font-size: 14px;
    }

    #header0, #header1, #header2, #header3, #header4,
    #header5, #header6, #header7, #header8, #header9 {
        font-size: 18px !important;
    }

    /* Contact */
    .contactContent {
        flex-direction: column;
        align-items: center;
    }

    .contactContent__section {
        width: 100% !important;
        max-width: 500px;
    }

    .contactContent__section:first-of-type {
        margin-bottom: 40px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .contactContent__frame {
        width: 100%;
    }

    .textAlignRight * {
        text-align: left;
    }

    .singleProduct__mainImage {
        height: auto;
    }
}

@media(max-width: 768px) {
    *:not(.singleProduct, .singleProduct *, .App, html, head,.topBar, .topBar *,
#root, body, .topMenu, .topMenu *) {
        zoom: unset;
    }

    .footer__menu--topBar .footer__list--region {
        display: none;
    }

    .footer__list__item {
        font-size: 16px;
        margin-top: 20px;
    }

    .flex-mobile {
        flex-direction: column;
    }

    .contactForm__btn {
        font-size: 14px;
    }

    .contactForm__success {
        font-size: 14px;
    }

    .startPage__img--desktop {
        display: none;
    }

    .startPage__img--mobile {
        display: block;
    }

    .startPage__header {
        font-size: 16px;
    }

    .startPage__inner__logo {
        width: 150px;
    }

    .input--masked {
        height: 40px;
    }

    .zipCodeResponse span {
        font-size: 14px;
        max-width: 90%;
    }

    .modal__inner__text {
        font-size: 14px;
    }

    iframe {
        max-width: 90%;
        max-height: 70vh;
    }

    .flex-gap {
        flex-direction: column;
        width: 95%;
    }

    .btn--showMap {
        height: 50px;
    }

    .flex-gap .btn--showMap {
        font-size: 10px;
        height: 40px;
        width: 100%;
    }

    .startPage__buttons {
        flex-direction: column;
        gap: 15px;
    }

    .startPage__buttons .slider__item__btn {
        height: 40px;
        border-radius: 40px;
    }

    .ribbonBtnLabel {
        font-size: 14px;
    }

    .shippingAndPayment {
        margin-top: 80px;
    }

    .cart__header--shippingAndPayment {
        display: none;
    }

    .singleProduct__options__buttons--lower .singleProduct__options__btn--version {
        max-width: 150px;
    }

    .panelContent__cart__sum {
        bottom: -160px;
    }

    .offerContent {
        padding-left: 5%;
        padding-right: 5%;
    }

    .singleProduct__title {
        font-size: 24px;
    }

    .priceSection .button--addToCart {
        font-size: 14px;
        height: 40px;
        border-radius: 40px;
    }

    .singleProduct__price {
        font-size: 18px;
    }

    .sliderMobile__dots {
        position: absolute;
        bottom: 5px;
        left: 50%;
        height: 50px !important;
        transform: translateX(-50%);
        display: flex !important;
        justify-content: center;
        align-items: center;
    }

    .sliderMobile__dots .dot {
        margin: 0 10px;
        width: 15px;
        height: 15px;
        background: rgb(211, 207, 201);
        border-radius: 50%;
    }

    .sliderMobile__dots .dot--active {
        background: rgb(217, 159, 84);
    }

    .sliderMobile {
        position: relative;
    }

    .sliderMobile, .sliderMobile > div,
    .sliderMobile > div > div, .sliderMobile a {
        height: auto;
        max-height: 800px;
        display: block;
    }

    .sliderMobile .img {
        width: 100%;
        height: auto;
    }

    .landing {
        padding: 52px 0 50px;
    }

    .afterSlider {
        max-width: 90%;
        margin: 30px auto 0;
    }

    .afterLanding__header {
        font-size: 24px;
    }

    .afterSlider__header {
        font-size: 21px;
    }

    .slider__item__btn--afterSlider {
        width: 100%;
        max-width: 350px;
        height: 50px;
        border-radius: 50px;
        font-size: 15px;
    }

    .offerContent__grid {
        margin-bottom: 50px;
    }

    /* Landing */
    .landing__content {
        left: unset;
        right: 0;
        transform: translate(0, -50%);
        top: unset;
        bottom: 0%;
    }

    /* Home page section */
    .homePageSection__header {
        font-size: 26px;
    }

    .button--homePageSection {
        width: clamp(200px, 60%, 300px);
        height: 50px;
        margin-top: 15px;
        font-size: 14px;
    }

    .button--homePageSection__link {
        font-size: 14px;
    }

    /* Offer content */
    .offerContent__grid {
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .offerContent__item {
        max-width: 400px;
    }

    .offerContent__header {
        font-size: 32px;
    }

    .offerContent__header--2 {
        font-size: 21px;
    }

    .offerContent__item__header--cursive {
        font-size: 15px;
    }

    /* Single product */
    .singleProduct {
        padding-bottom: 50px;
    }

    .singleProduct > *, .singleProduct__bottom {
        width: 100%;
        max-width: 500px;
    }

    .singleProduct__left {
        margin: 0 0 50px 0;
    }


    .singleProduct__bottom {
        padding: 0;
    }

    .singleProduct__bottom__btn {
        height: 50px;
        width: 100%;
    }

    /* Cart */
    .cartContent {
        background: #fff;
        padding: 30px 0;
    }

    .cart {
        border: none;
        padding: 25px;
    }

    .cart__header {
        padding-left: 25px;
        font-size: 17px;
    }

    /* Shipping and payemnt */
    .shippingAndPayment__form label {
        width: 100% !important;
    }

    .ribbonBtnLabel--chooseHour {
        display: flex;
    }

    /* Single product */
    .singleProduct__bottom__btn {
        font-size: 15px;
    }

    .singleProduct__longDesc p, .singleProduct__parts li {
        font-size: 14px;
    }

    .singleProduct {
        margin-bottom: 0;
    }

    .singleProduct__bottom {
        margin-top: 30px;
    }

    .modalHeader {
        font-size: 17px;
    }

    .emptyCart {
        text-align: center;
        font-size: 17px;
        margin: 20px 0 20px;
    }

    .button--emptyCart {
        margin: 0 auto;
        display: block;
        font-size: 14px;
    }

    /* Admin */
    .orderItem .panelContent__column {
        flex-basis: 49%;
    }

    .panelContent__column__value,
    .panelContent__column__label {
        font-size: 12px !important;
    }

    .panelContent__filters__label,
    .panelContent__filters__label__label,
    .panelContent__sortBtn
    {
        font-size: 13px;
    }

    .panelContent__filters__label__label {
        margin: 0;
    }

    .panelContent__filters__label {
        margin-right: 5px;
    }

    .panelContent__input__label {
        width: 180px;
    }

    .panelContent__input {
        width: calc(100% - 20px);
    }

    .panelContent__filters__btn {
        margin-left: 10px;
    }

    .productItem .panelContent__column {
        flex-basis: 48%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .panelContent__frame__form {
        width: 100%;
        margin: 0;
    }

    .panelContent__frame__header {
        font-size: 17px;
    }

    .categoriesForm .addProduct__label {
        margin: 10px 0;
    }

    .paymentContent .labelSpan {
        text-align: center;
    }

    .panelContent__input__label--payment {
        width: 100%;
    }

    .addProduct__btn {
        padding: 10px;
    }

    .panelContent__twoForms {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .settingsForm {
        width: 100%;
        margin: 40px 0;
    }

    .panelContent__cart__item {
        flex-wrap: wrap;
    }

    .panelContent__cart__column {
        flex-basis: 30%;
        text-align: left;
        white-space: nowrap;
    }

    .panelContent__data {
        width: 100% !important;
        padding: 12px;
        font-size: 13px;
    }

    .panelContent__cart__header {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__cart__header > *:last-child {
        margin-bottom: 20px;
    }

    .panelContent__cart__header .btn--neutral {
        margin-left: 0;
    }

    .panelContent__cart__column {
        border: 1px solid rgba(0, 0, 0, .25);
        padding: 10px !important;
    }

    .panelContent__cart__column span {
        font-weight: 400;
        font-size: 13px;
    }

    .panelContent__header--smaller {
        order: 2;
    }

    .panelContent__buttons {
        order: 1;
    }

    .addProduct__form--addProduct {
        width: 100%;
    }

    .panelContent__ribbons__item {
        flex-direction: column;
        align-items: flex-start;
    }

    .panelContent__ribbons__column--caption {
        border-left: none;
        padding-left: 0;
        border-top: 1px solid #000;
    }

    .footer__header, .footer__menu__item__link {
        font-size: 12px;
    }

    .footer__menu--footer .footer__menu__item:last-of-type {
        margin-right: 8px;
    }

    /* Blog */
    .blogContent {
        margin-top: 30px;
    }

    .blogContent__item {
        width: 100%;
    }

    .blogContent__item__title {
        font-size: 18px;
    }

    /* Admin */
    .panelContent__othersSection {
        width: 100%;
        margin-top: 20px;
    }

    /* Banquet */
    .singleProduct--banquet * {
        max-width: none !important;
    }

    .banquet__product__title {
        font-size: 15px;
        padding: 10px;
    }

    .singleProduct--banquet .panelContent__filters__btnWrapper {
        font-size: 13px;
    }

    .singleProduct--banquet .panelContent__filters__btn--options {
        margin-right: 8px;
    }

    .singleProduct--banquet .singleProduct__price {
        white-space: normal;
    }

    .singleProduct--banquet .button--addToCart {
        width: 100% !important;
    }

    /* Shipping and payment */
    .cart__summary--shippingAndPayment {
        max-width: 90%;
        margin: auto;
        flex-wrap: wrap;
    }

    .cart__summary__button--back {
        margin-right: 20px;
        display: none;
    }

    .cart__summary__bottomRight {
        margin-top: 20px;
    }

    .cart__summary--shippingAndPayment {
        padding: 0;
    }

    .calendarMonth {
        font-size: 9px;
    }
}

@media(max-width: 576px) {
    .singleProduct__options__header {
        max-width: 85px;
    }

    /* Banquet */
    .banquet__product__title {
        flex-basis: 100%;
        margin-bottom: 25px;
    }

    .singleProduct__left {
        margin-bottom: 20px;
    }

    .singleProduct__right__header {
        margin-top: 10px;
    }

    .singleProduct__images__item {
        height: 75px;
    }

    .banquet__btn {
        flex-basis: 30%;
    }

        /* Landing page */
    .landing__logo {
        width: 140px;
    }

    .landing__header {
        font-size: 22px;
        margin: 40px;
        text-align: center;
    }

    .button--landing {
        width: 250px;
        height: 50px;
    }

    .landing {
        /*background: url("../img/landingMobile.png");*/
        /*background-size: cover;*/
    }

    .button--landing__link {
        font-size: 13px;
    }

    .offerContent__grid {
        grid-row-gap: 40px;
        margin-top: 20px;
    }

    /* Home page section */
    .homePageSection {
        padding-top: 30px;
        padding-bottom: 0;
    }

    .topBar .footer__header {
        display: none;
    }

    .topBar .callToAction {
        margin-left: 0;
    }

    /* Single product */
    .singleProduct__options__buttons {
        white-space: normal;
    }

    .singleProduct--circle {
        width: 44px;
        height: 44px;
        font-size: 19px !important;
    }

    .singleProduct__options__header {
        font-size: 12px;
    }

    /* Cart */
    .cart__header {
        text-align: center;
        padding: 0 25px;
    }

    .shippingAndPayment__input::placeholder {
        font-size: 12px;
    }

    .cart__item {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 150px repeat(3, 1fr);
        grid-row-gap: 15px;
        justify-items: center;
        margin-bottom: 60px;
    }

    .cart__item__imgWrapper {
        grid-column: 1 / span 3;
        grid-row: 1;
        margin-bottom: 80px;
    }

    .firstCol {
        grid-column: 1;
        grid-row: 2;
    }

    .firstCol .cart__item__value {
        font-weight: 700;
    }

    .secondCol {
        grid-column: 1;
        grid-row: 3;
    }

    .thirdCol {
        grid-column: 1;
        grid-row: 4;
    }

    .fourthCol {
        grid-column: 2;
        grid-row: 2;
    }

    .fifthCol {
        grid-column: 2;
        grid-row: 3;
    }

    .sixthCol {
        grid-column: 2;
        grid-row: 4;
    }

    .cart__summary {
        align-items: center;
    }

    /* Single product */
    .singleProduct__options__btn {
        font-size: 10px;
        padding: 6px 10px;
    }

    .singleProduct__options__buttons {
        margin-left: 10px;
    }

    .smallModal .ReactModal__Content {
        width: 100%;
        height: 101%;
    }

    .cart__summary--shippingAndPayment {
        margin-top: -50px;
    }

    .cart__summary__button--shippingAndPayment:hover {
        letter-spacing: 1px;
    }

    .singleProduct__options__buttons--sizes .singleProduct__options__btn {
        width: 44px;
        height: 44px;
        font-size: 17px !important;
    }

    .ty__header {
        font-size: 24px;
    }

    .ty__subheader {
        font-size: 17px;
    }

    .modal__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    /* Single product */
    .singleProduct__bottom__btn, .button--addToCart {
        font-size: 14px;
    }

    .singleProduct__title,
    .singleProduct__title--cursive {
        text-align: left;
    }

    /* Admin */
    .panelContent__start__item {
        width: 48%;
    }

    .panelContent__header__h {
        font-size: 17px;
    }

    .panelContent__header__back {
        font-size: 13px;
    }

    .panelContent__header__back__img {
        width: 18px;
        margin-left: 16px;
    }

    .panelModal .ReactModal__Content {
        width: 100% !important;
        height: 100vh !important;
    }

    /* Coupons */
    .couponSection {
        flex-direction: column;
        align-items: flex-start;
    }

    .button--coupon {
        width: 200px;
    }

    .errorsContainer--coupon {
        text-align: center;
        display: block;
        margin: 10px auto;
    }

    /* Calendar */
    .shippingAndPayment__section--hours {
        grid-template-columns: 100%;
        grid-template-rows: repeat(7, 1fr);
    }

    .noSlotsAvailable {
        font-size: 12px;
    }

    .ribbonBtnLabel--hour {
        font-size: 14px;
    }

    .shippingAndPayment__section--noMarginBottom,
    .shippingAndPayment__section--hours
    {
        margin-bottom: 0;
    }

    /* Shipping and payment */
    .routeSection__btn {
        width: 200px;
        font-size: 13px !important;
    }

    .routeSection__btn:hover {
        letter-spacing: 0;
    }

    .afterFormSection {
        flex-direction: column;
    }

    .extraInputs {
        order: 5;
    }

    .cart__summary__header {
        justify-content: flex-start;
    }
}

@media(min-width: 996px) {
    .offerContent--offer {
        margin-top: 210px;
    }
}
